<template>
  <div>
    <div style="margin: 10px 0 10px 10px">
      <el-row>
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          @click="dialogVisible = true"
          >新增</el-button
        >
      </el-row>
    </div>

    <div class="add_dialog">
      <el-dialog
        :title="`${form.id ? '编辑' : '添加'}认证类型`"
        :visible.sync="dialogVisible"
        width="600px"
        @close="handleDialogClose"
        :before-close="handleClose"
        center
      >
        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">名称：</p>
          <div class="addAdmin_select">
            <el-input v-model="form.name" placeholder="请输入名称" clearable />
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">地区：</p>
          <el-select
            v-model="form.area"
            placeholder="请选择地区"
            class="addAdmin_select"
            clearable
            filterable
            multiple
          >
            <el-option
              v-for="(item, i) in countryList"
              :key="i"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">备注：</p>
          <div class="addAdmin_select">
            <el-input
              v-model="form.remark"
              type="textarea"
              :rows="2"
              placeholder="请输入备注"
              clearable
            />
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">上传1张图标：</p>
          <el-upload
            :action="`/a1/upload/file`"
            accept=".jpg, .png"
            list-type="picture-card"
            :before-upload="handleBeforeUpload"
            :http-request="handleUpload"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过3MB
            </div>
          </el-upload>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button
            v-loading="isLoading.table"
            type="primary"
            @click="handleDialogConfirm"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>

    <div class="table_style">
      <el-table
        ref="multipleTable"
        v-loading="isLoading.table"
        header-align="center"
        border
        class="main-table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID" align="center" />
        <el-table-column prop="name" label="证书名称" align="center" />
        <el-table-column prop="remark" label="备注" align="center" />
        <el-table-column prop="icon" label="图标" align="center">
          <template slot-scope="scope">
            <img
              style="width: 80px; height: 80px; border: none"
              :src="scope.row.icon"
            />
          </template>
        </el-table-column>
        <el-table-column prop="area" label="适用地区" align="center" />
        <el-table-column prop="created_at" label="创建时间" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.created_at
                ? new Date(row.created_at * 1000).toLocaleString("zh-Hans-CN")
                : "-"
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="operation"
          label="操作"
          align="center"
          width="300"
        >
          <template slot-scope="{ row, $index }">
            <div>
              <el-button
                type="warning"
                size="small"
                plain
                icon="el-icon-edit-outline"
                style="margin-right: 10px"
                @click.stop="handleEditAuth(row)"
                >编辑</el-button
              >

              <el-popover trigger="click" placement="top">
                <p>你确认要删除此认证类型吗?</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="hidePopover()"
                    >取消</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="handleDeleteAuth(row.id, $index)"
                    >确认</el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  plain
                  icon="el-icon-delete"
                  type="danger"
                  size="small"
                  @click.stop
                  >删除</el-button
                >
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div ref="footer" class="footer">
      <el-pagination
        :key="reloadPaginate"
        :current-page="pagination.page"
        :page-size="pagination.pageSize"
        :page-sizes="[10, 20, 50, 100, 200]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="pagination.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: "",
        remark: "",
        area: "",
      },
      tableData: [],
      isLoading: {
        table: false,
      },
      reloadPaginate: 0,
      pagination: {
        total: 0,
        page: 1,
        pageSize: 20,
      },
      dialogVisible: false,
      countryList: [],
      iconList: [],
    };
  },
  created() {
    this.getTableData();
    this.getCountryList();
  },
  methods: {
    handleBeforeUpload(file) {
      const limit = 3 * 1024 * 1024;
      if (file.size > limit) {
        this.$message.warning("不能超过3MB");
        return false;
      }
    },
    // 分页跳转
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getTableData();
    },
    //弹窗关闭
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done(_);
        })
        .catch((_) => {
          _;
        });
    },
    //获取到认证类型列表
    getTableData() {
      this.tableData = [];
      this.pagination.total = 0;
      this.$axios
        .get("/a1/cred/type", {
          params: {
            pageSize: this.pagination.pageSize,
            page: this.pagination.page,
          },
        })
        .then((res) => {
          const { data } = res.data;
          this.pagination.total = data.total;
          this.tableData = data.data;
          this.reloadPaginate++;
        });
    },
    // 深拷贝
    handleEditAuth(row) {
      const formData = JSON.parse(JSON.stringify(row));
      this.form.name = formData.name;
      this.form.remark = formData.remark;
      this.form.area = formData.area.split(",");
      this.icon = formData.icon;
      this.form.id = formData.id;
      this.dialogVisible = true;
    },
    // 编辑跟新增
    handleDialogConfirm() {
      this.isLoading.table = true;
      if (this.form.id) {
        this.$axios
          .put(`/a1/cred/type/${this.form.id}`, {
            name: this.form.name,
            remark: this.form.remark,
            area: this.form.area.join(","),
            icon: this.icon,
          })
          .then(() => {
            this.$message.success("修改成功");
            this.getTableData();
            this.dialogVisible = false;
          })
          .finally(() => {
            this.isLoading.table = false;
          });
        return;
      }
      this.$axios
        .post("/a1/cred/type", {
          name: this.form.name,
          remark: this.form.remark,
          area: this.form.area.join(","),
          icon: this.icon,
        })
        .then(() => {
          this.$message.success("新增成功");
          this.getTableData();
          this.dialogVisible = false;
        })
        .finally(() => {
          this.isLoading.table = false;
        });
    },
    // 清空表格数据
    handleDialogClose() {
      this.form = {
        name: "",
        remark: "",
        area: "",
      };
      this.icon = "";
    },
    // 删除
    handleDeleteAuth(id, index) {
      this.isLoading.table = true;
      this.$axios
        .delete(`/a1/cred/type/${id}`)
        .then(() => {
          this.tableData.splice(index, 1);
          this.$notify({
            title: "成功",
            message: "删除认证类型",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "失败",
            message: "删除认证类型",
          });
        })
        .finally(() => {
          this.hidePopover();
          this.isLoading.table = false;
        });
    },
    hidePopover() {
      this.$refs.footer.click();
    },
    //获取全部国家
    getCountryList() {
      this.countryList = [];
      this.$axios.get(`/a1/country`).then((res) => {
        const countryList = [];
        const resData = res.data.data;
        for (let i = 0; i < resData.length; i++) {
          let newData = {
            value: resData[i].code,
            label: resData[i].name,
          };
          countryList.push(newData);
        }
        this.countryList = countryList;
      });
    },
    //上传图片
    handleUpload(e) {
      const formdata = new FormData();
      formdata.append("file", e.file);
      this.$alert("正在上传，请稍候...", {
        showConfirmButton: false,
        showClose: false,
      });
      this.$axios
        .post("/a1/upload/file", formdata)
        .then((res) => {
          const { status } = res;
          if (status == "200") {
            const { data } = res.data;
            this.icon = data;
          }
        })
        .finally(() => {
          this.$msgbox.close();
        });
    },
    handleRemove(file, fileList) {},
  },
};
</script>
<style lang="scss" scoped>
.input_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 300px;
  margin: 10px 0 10px 10px;
}
.input_text {
  width: 120px;
  color: #889aa4;
}

.footer {
  margin: 60px;
  text-align: center;
}
.addAdmin {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;

  &_CharacterSet {
    font-size: 15px;
    width: 120px;
    text-align: right;
  }

  &_select {
    flex: 1;
  }
}
.table_style {
  margin-left: 10px;
}
</style>
